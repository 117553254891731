<template>
  <qtm-btn :loading="loading" secondary @click="confirm = true">
    Create Order
    <qtm-dialog-card v-model="confirm" title="Create order from this email?">
      <qtm-radio-group v-model="orderType" class="ml-3">
        <qtm-radio value="CSD">
          <template v-slot:label>
            <div class="text-secondary">
              Create <b>CSD</b> order
            </div>
          </template>
        </qtm-radio>
        <qtm-radio value="RFQ">
          <template v-slot:label>
            <div class="text-secondary">
              Create <b>RFQ</b> order
            </div>
          </template>
        </qtm-radio>
        <qtm-radio value="INVOICE">
          <template v-slot:label>
            <div class="text-secondary">
              Create <b>CSD INVOICE</b> order <span class="text-caption text-grey">(Using CSD flow)</span>
            </div>
          </template>
        </qtm-radio>
        <qtm-radio value="INVOICE_APPROVAL">
          <template v-slot:label>
            <div class="text-secondary">
              Create <b>INVOICE</b> order
            </div>
          </template>
        </qtm-radio>
      </qtm-radio-group>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn :loading="creatingOrder" tertiary @click="confirm = false">
          Cancel
        </qtm-btn>
        <qtm-btn :loading="creatingOrder" @click="createOrder">
          Confirm
        </qtm-btn>
      </template>
    </qtm-dialog-card>
  </qtm-btn>
</template>

<script setup lang="ts">
export interface Props {
  email: any
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false
})
const emit = defineEmits(['order-created'])

const confirm = ref(false)
const creatingOrder = ref(false)
const orderType = ref<'CSD' | 'RFQ' | 'INVOICE' | 'INVOICE_APPROVAL'>('CSD')

const { $api, $error } = useNuxtApp()
const createOrder = async () => {
  const data = {
    email: props.email.id,
    from_call_to_vendor: orderType.value === 'CSD' || orderType.value === 'INVOICE',
    is_invoice: orderType.value === 'INVOICE',
    is_invoice_approval: orderType.value === 'INVOICE_APPROVAL'
  }

  creatingOrder.value = true
  try {
    const order = await $api.v1.rfqs.fromEmail(data)

    emit('order-created', order)
  }
  catch (error) {
    $error.report(error)
  }
  confirm.value = false
  creatingOrder.value = false
}
</script>
