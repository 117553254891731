<template>
  <v-hover>
    <template v-slot="{ isHovering, props: hoverProps }">
      <v-chip
        v-if="email.classification"
        v-bind="hoverProps"
        class="text-uppercase"
        :style="reported? 'cursor: not-allowed': 'cursor: pointer'"
        color="secondary"
        density="comfortable"
        label
        title="Email classification"
        @click="report"
      >
        <v-icon icon="mdi-information-outline" start />
        {{ email.classification.replaceAll('_', ' ') }}
        <v-overlay
          :model-value="isHovering || reported"
          class="align-center justify-center text-white report-overlay"
          contained
          persistent
          :scrim="reported ? 'success': 'error'"
        >
          <v-icon :icon="reported ? 'mdi-check' : 'mdi-bug'" start />
          {{ reported ? "Thanks" : "Report" }}
        </v-overlay>
      </v-chip>
    </template>
  </v-hover>
</template>

<script setup lang="ts">
export interface Props {
  email: any
}
const props = defineProps<Props>()

const reported = ref(false)

const { $tracker } = useNuxtApp()

const report = () => {
  if (!reported.value) {
    $tracker.track('Wrong email classification', {
      classification: props.email.classification,
      email_id: props.email.id,
    })
    reported.value = true
  }
}
</script>
<style>
.report-overlay div{
  opacity: 1 !important;
}
</style>
