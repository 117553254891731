import { ref } from 'vue'

export interface Pin {
  id: number
  objectId: number
  type: string
  title: string
}

// Acts as local store when kept outside of the function
const globalPins = ref<Pin[]>([])
const localStorageRead = ref(false)

export function usePins() {
  const getPins = () => {
    if (localStorageRead.value) {
      return globalPins.value
    }
    globalPins.value = JSON.parse(localStorage.getItem('global-pins') || '[]')
    localStorageRead.value = true
    return globalPins.value
  }

  const setPins = (pins: Pin[]) => {
    globalPins.value = pins
    localStorage.setItem('global-pins', JSON.stringify(pins))
  }

  const isPinned = (objectId: number, type: string) => {
    return getPins().find(p => p.objectId === objectId && p.type === type)
  }

  const addPin = (pin: Pin) => {
    const pins = getPins()
    pin.id = pins.length + 1
    pins.push(pin)
    setPins(pins)
  }

  const removePin = (objectId: number, type: string) => {
    let pins = getPins()
    pins = pins.filter(p => p.objectId !== objectId || p.type !== type)
    setPins(pins)
  }

  const pins = computed({
    get: getPins,
    set: () => { console.error('Forbidden, please use addPin() or removePin() instead.') },
  })

  return {
    addPin,
    isPinned,
    pins,
    removePin,
  }
}
