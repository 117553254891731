<template>
  <qtm-dialog-card v-bind="$attrs" persistent title="Move email to another order" width="700">
    <qtm-icon-btn class="close-button" icon="mdi-close" @click="close" />
    <div>
      <b class="text--primary">From:</b>
      {{ email.from_address }}
    </div>
    <div>
      <b class="text--primary">To:</b>
      {{ email.to_addresses }}
    </div>
    <div v-if="email.cc_addresses">
      <b class="text--primary">CC:</b>
      {{ email.cc_addresses }}
    </div>
    <div>
      <b class="text--primary">Subject:</b>
      {{ email.subject }}
    </div>
    <div v-if="email.attachments && email.attachments.length">
      <b class="text--primary">Attachments:</b>
      <attachment-links-list :attachments="email.attachments" dense>
        <template v-if="isUnassociated && isInbound" v-slot:action="{ attachment }">
          <v-menu>
            <template v-slot:activator="{ props: activator }">
              <qtm-icon-btn v-bind="activator" icon="mdi-chevron-down" size="small" />
            </template>
            <v-list class="qtm-border">
              <v-list-item @click="createDraftPo(attachment)">
                <v-list-item-title>Create a Draft PO</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </attachment-links-list>
    </div>
    <div>
      <b class="text--primary">Body:</b>
      <div class="email-body-continer">
        <safe-html-renderer :html="email.body" />
      </div>
    </div>
    <br>
    <b class="text--primary">Move email to:</b>
    <v-row>
      <v-col cols="9">
        <order-search
          density="compact"
          max-width="485"
          @update:model-value="backendOrderSelected"
        />
      </v-col>
      <v-col cols="3">
        <qtm-btn :loading="loading" @click="moveEmail">
          Move
          <v-icon location="right">
            mdi-send
          </v-icon>
        </qtm-btn>
      </v-col>
    </v-row>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn tertiary @click="sendToSupport">
        <v-icon>mdi-face-agent</v-icon>
        Send to support
      </qtm-btn>
      <order-from-email-btn v-if="!email.rfq" :email="email" :loading="loading" @order-created="orderCreated" />
      <multi-action-btn v-if="!email.is_general_inbox" :actions="generalInboxActions" free-width />
    </template>
  </qtm-dialog-card>

  <qtm-dialog-card v-model="showMoveRelatedEmailsModal" persistent title="Move Multiple Emails" width="700">
    <qtm-icon-btn class="close-button" icon="mdi-close" @click="showMoveRelatedEmailsModal = false" />

    <v-row>
      <v-col>
        There {{ relatedEmails.length === 1 ? 'is' : 'are' }} {{ relatedEmails.length }}
        related unassociated email{{ relatedEmails.length === 1 ? '' : 's' }} that can also be moved.
      </v-col>
    </v-row>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn tertiary @click="showMoveRelatedEmailsModal = false">
        Cancel
      </qtm-btn>
      <qtm-btn :loading="loading" secondary @click="moveCurrentEmailOnly">
        Move this email only
      </qtm-btn>
      <qtm-btn :loading="loading" secondary @click="moveAllRelatedEmails">
        Move all
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>
import { mapActions } from 'pinia'
import { SEARCH_MAX_LENGTH } from '@/constants'
import AttachmentLinksList from '@/components/attachment-links-list.vue'
import MultiActionBtn from '@/components/multi-action-btn.vue'
import OrderSearch from '@/components/search/order-search.vue'
import OrderFromEmailBtn from '@/components/admin/emails/order-from-email-btn.vue'
import SafeHtmlRenderer from '@/components/admin/emails/safe-html-renderer.vue'

export default {
  name: 'move-email',
  components: {
    AttachmentLinksList, MultiActionBtn, OrderSearch, OrderFromEmailBtn, SafeHtmlRenderer
  },
  props: {
    email: {
      type: Object,
      required: false,
      default: null
    },
    maxlength: {
      type: [Number, String],
      default: SEARCH_MAX_LENGTH
    },
    openOrderAfterMoving: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'update:email'],
  data() {
    return {
      generalInboxActions: [
        { label: 'General Inbox', onClick: this.moveToGeneralInbox },
        { label: 'Move & Mark as Read', onClick: () => this.moveToGeneralInbox({ markAsRead: true }) },
      ],
      loading: false,
      order: null,
      orders: [],
      relatedEmails: [],
      showMoveRelatedEmailsModal: false,
      useBackendSearch: false
    }
  },
  computed: {
    isInbound() {
      return this.email?.email_type === 'inbound'
    },
    isUnassociated() {
      return !this.email?.rfq
    },
  },
  watch: {
    email() {
      this.order = null
    }
  },
  methods: {
    ...mapActions(useAdminStore, ['activateOrder', 'updateOrder', 'removeUnassociatedEmail']),
    ...mapActions(useOrdersStore, ['setOrderFromEmail']),
    async createDraftPo(attachment) {
      try {
        const [owner] = await this.$api.v1.users.list({ email: this.email.from_address })

        this.setOrderFromEmail({
          owner,
          quote_attachment: attachment,
          unassociated_email: this.email.id,
        })
        this.close()
        this.$router.push({ name: 'purchase-orders-new' })
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    orderName(order) {
      const requesterName = order.requester_last_name
        ? ` (${order.requester_first_name} ${order.requester_last_name})`
        : ''
      return `${order.qtm_reference_number} - ${order.reference_name}${requesterName}`
    },
    close() {
      this.$emit('close')
    },
    async moveToGeneralInbox({ markAsRead = false } = {}) {
      this.loading = true

      const data = { is_general_inbox: true, rfq: null }

      if (markAsRead) {
        data.tracking_status = 'opened'
      }

      try {
        const email = await this.$api.v1.notifications.email.patch(this.email.id, data)

        if (markAsRead) {
          await this.$api.v1.notifications.email.markAsRead(this.email.id)
        }

        this.$emit('update:email', email)
        this.close()

        if (this.openOrderAfterMoving) {
          this.removeUnassociatedEmail(this.email)
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async sendToSupport() {
      this.loading = true
      try {
        await this.$api.v1.notifications.email.moveToSupport(this.email.id, {})
        this.close()
        if (this.openOrderAfterMoving) {
          this.removeUnassociatedEmail(this.email)
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async moveEmail() {
      if (!this.order) {
        this.$toast.warning('Please choose an order to move the email to.')
        return
      }
      if (this.email.rfq === this.order) {
        this.$toast.warning('This email is currently associated to that order, please choose another one.')
        return
      }

      this.loading = true

      this.relatedEmails = await this.$api.v1.notifications.email.getUnassociatedReferencedEmails(this.email.id)

      if (this.relatedEmails.length) {
        this.showMoveRelatedEmailsModal = true
      }
      else {
        await this.moveSingleEmail(this.email.id)
        this.close()
        if (this.openOrderAfterMoving) {
          this.activateOrder({ id: this.order })
        }
      }
      this.loading = false
    },
    async moveCurrentEmailOnly() {
      this.loading = true
      await this.moveSingleEmail(this.email.id)
      this.close()
      if (this.openOrderAfterMoving) {
        this.activateOrder({ id: this.order })
      }
      this.loading = false
    },
    async moveAllRelatedEmails() {
      this.loading = true
      try {
        const mainEmailPromise = this.moveSingleEmail(this.email.id)
        await Promise.all(this.relatedEmails.map(email => this.moveSingleEmail(email.id)))
        await mainEmailPromise
        this.close()
        if (this.openOrderAfterMoving) {
          this.activateOrder({ id: this.order })
        }
        this.close()
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async moveSingleEmail(emailId) {
      try {
        const email = await this.$api.v1.notifications.email.patch(
          emailId,
          { rfq: this.order, is_general_inbox: false }
        )
        if (emailId === this.email.id) {
          this.$emit('update:email', email)
        }
        this.removeUnassociatedEmail(email)
        return email
      }
      catch (error) {
        this.$toast.error(error)
        this.$error.report(error)
        return null
      }
    },
    orderCreated(order) {
      this.removeUnassociatedEmail(this.email)
      this.updateOrder(order)
      this.activateOrder(order)
      this.close()
    },
    backendOrderSelected(order) {
      this.order = order?.id ?? undefined
    },
  },
}
</script>
<style scoped>
  .move-email-title {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .email-body-continer {
    max-height: 300px;
    overflow-y: auto;
  }
</style>
