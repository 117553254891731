<template>
  <v-dialog
    v-bind="$attrs"
    fullscreen
    hide-overlay
    no-click-animation
    persistent
  >
    <v-card class="non-scrollable-container" tile>
      <div class="d-flex align-center bg-secondary text-white">
        <qtm-icon-btn color="white" icon="mdi-close" @click="confirmClose = true" />
        <span v-if="email" class="qtm-h2 py-2">
          Create invoice for email: "{{ email.subject }}"
        </span>
      </div>

      <div class="d-flex non-scrollable-container">
        <div v-if="showAttachment" class="attachment-viewer">
          <attachment-viewer
            class="pa-2 mr-2"
            select-text-layer
            :src="documentToPreviewSrc"
            :name="documentToPreviewName"
            @close="showAttachment = false"
          />
        </div>
        <v-card-text
          class="bg-background scrollable-container mb-16"
          :class="{'attachment-visible': showAttachment}"
        >
          <invoice-form
            ref="form"
            v-model:attachments="attachments"
            v-model:comment="comment"
            v-model:due-date="dueDate"
            v-model:invoice-number="invoiceNumber"
            v-model:issue-date="issueDate"
            v-model:items="items"
            v-model:loading="loading"
            :full-width="showAttachment"
            :order="order"
            :project="project"
            readonly-order
            :supplier="supplier"
          >
            <template v-slot:attach-invoice>
              <qtm-content-block>
                <qtm-input-label v-if="email && email.attachments.length" label="Select Attachments">
                  <p class="text-caption">
                    The selected attachments will be duplicated and associated to the invoice.
                  </p>
                  <order-attachments
                    v-model="email.attachments"
                    v-model:selected-attachments="selectedAttachments"
                    allow-preview
                    allow-select
                    no-upload
                    no-delete
                    remove-title
                    @preview="showDocumentPreview"
                  >
                    <template v-slot:action="{ attachment }">
                      <attachment-analyzer
                        v-model:date-issued="issueDate"
                        v-model:items="items"
                        v-model:supplier-reference="invoiceNumber"
                        :attachment="attachment"
                        :units="units"
                        @analyzing="showDocumentPreview"
                        @update:items="itemsUpdated"
                      />
                    </template>
                  </order-attachments>
                </qtm-input-label>
              </qtm-content-block>
              <order-attachments
                v-model="attachments"
                allow-preview
                @preview="showDocumentPreview"
              >
                <template v-slot:action="{ attachment }">
                  <attachment-analyzer
                    v-model:date-issued="issueDate"
                    v-model:items="items"
                    v-model:supplier-reference="invoiceNumber"
                    :attachment="attachment"
                    :units="units"
                    @analyzing="showDocumentPreview"
                    @update:items="itemsUpdated"
                  />
                </template>
              </order-attachments>
            </template>
          </invoice-form>

          <v-divider class="my-8" />
          <div class="text-center">
            <qtm-btn class="mr-4" :disabled="!items.length" secondary size="x-large" @click="showAutoMatchItems = true">
              Match Items
            </qtm-btn>
            <qtm-btn :loading="loading" size="x-large" @click="submit">
              Send for Approval
            </qtm-btn>
          </div>
        </v-card-text>
      </div>
    </v-card>
    <confirmation-dialog v-model="confirmClose" title="Do not create invoice" @confirm="close">
      This data will not be saved, are you sure you want to close?
    </confirmation-dialog>
    <auto-match-items
      v-model="showAutoMatchItems"
      v-model:items="items"
      v-model:loading="loading"
      :order-items="order?.pos[0]?.poskus"
      @cancel="openMatch"
      @done="openMatch"
    />
    <match-items-dialog
      v-model="matchItems"
      v-model:items-to-match="items"
      :match-items="poItems"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import type { DateTime, Jobsite, Order } from '@quotetome/materials-api'
import AttachmentAnalyzer from '@/components/attachments/attachment-analyzer.vue'
import AttachmentViewer from '@/components/attachments/attachment-viewer.vue'
import AutoMatchItems from '@/components/items/auto-match-items.vue'
import ConfirmationDialog from '@/components/confirmation-dialog.vue'
import InvoiceForm from '@/components/invoices/invoice-form.vue'
import MatchItemsDialog from '@/components/items/match-items-dialog.vue'
import OrderAttachments from '@/components/orders/order-attachments.vue'
import useUnits from '@/composables/units'

export interface Props {
  email?: any
  order: Order
}

const props = defineProps<Props>()
const emit = defineEmits(['created', 'update:model-value'])

const project = computed(() => props.order.jobsite as Jobsite)

const { units } = useUnits(props.order.jobsite?.company as number)

const attachments = ref<any>([])
const comment = ref('')
const confirmClose = ref(false)

const po = props.order.pos[0]
const poItems = po?.poskus ?? []

const documentToPreviewSrc = ref('')
const documentToPreviewName = ref('')
const dueDate = ref<DateTime | null>(null)
const invoiceNumber = ref('')
const issueDate = ref<DateTime | null>(null)
const items = ref<any[]>([])
const loading = ref(false)
const selectedAttachments = ref([])
const showAttachment = ref(false)
const supplier = ref(null)

const showAutoMatchItems = ref(false)
const matchItems = ref(false)

const openMatch = () => {
  showAutoMatchItems.value = false
  matchItems.value = true
}

const { $api, $error, $toast } = useNuxtApp()
const fetchVendor = async () => {
  if (!props.order.pos[0]) {
    return
  }

  try {
    supplier.value = await $api.v1.vendors.get(props.order.pos[0].supplier as any as number)
  }
  catch (error) {
    $error.report(error)
  }
}

onMounted(fetchVendor)

const close = () => {
  confirmClose.value = false
  emit('update:model-value', false)
}

const showDocumentPreview = (attachment: any) => {
  documentToPreviewName.value = attachment.name
  documentToPreviewSrc.value = attachment.url
  showAttachment.value = true
}

const form = ref<typeof InvoiceForm>()

const itemsUpdated = () => {
  form.value?.scrollToCart()
}

const submit = async () => {
  if (!form.value?.isValid()) {
    $toast.error('Please correct the errors')
    return
  }

  const payload = {
    attachments: attachments.value.map((attachment: any) => attachment.id),
    comments: comment.value,
    date_due: dueDate.value?.unix() || null,
    date_issued: issueDate.value!.unix(),
    email_attachments: selectedAttachments.value,
    invoice_number: invoiceNumber.value,
    order: props.order.id,
    revision: {
      poskus: items.value.map(item => ({
        comment: item.comment,
        cost_code: item.cost_code,
        day_rate: item.day_rate,
        description: item.description,
        month_rate: item.month_rate,
        quantity: item.quantity,
        reference_identifier: item.reference_identifier || null,
        rental_duration: item.rental_duration,
        rental_duration_unit: item.rental_duration_unit,
        tax: item.tax,
        unit: item.unit,
        unit_price: item.unit_price,
        week_rate: item.week_rate,
      }))
    },
  }

  loading.value = true
  try {
    emit('created', await $api.v1.invoices.create(payload))
    $toast.success('Invoice created')
    attachments.value = []
    comment.value = ''
    dueDate.value = null
    invoiceNumber.value = ''
    issueDate.value = null
    items.value = []
    selectedAttachments.value = []
    emit('update:model-value', false)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}
</script>

<style lang="scss" scoped>
.attachment-viewer {
  flex-shrink: 0;
  margin-top: 30px;
  width: 50%;
  overflow-y: auto;
}

.attachment-visible {
  max-width: 50% !important;
}

.non-scrollable-container {
  height: 100%;
  overflow-y: hidden;
}

.scrollable-container {
  overflow-y: auto;
}
</style>
