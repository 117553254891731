<template>
  <div class="white">
    <move-email
      v-model="showMoveModal"
      :email="emailContent"
      @close="close"
      @update:email="onEmailUpdate"
    />
    <v-row class="rows email-data px-6" :data-email-id="emailContent.id" no-gutters>
      <v-col :cols="inlineAttachments.length ? 5 : 6">
        <div>
          <v-label>
            Subject:
          </v-label>
          <span class="cursor-text" v-text="emailContent.subject" />
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label>
            From:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.from_address) }}</span>
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label>
            To:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.to_addresses) }}</span>
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label v-if="emailContent.cc_addresses">
            CC:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.cc_addresses) }}</span>
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label v-if="emailContent.bcc_addresses">
            BCC:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.bcc_addresses) }}</span>
        </div>
        <div class="show-more" @click="toggleShowMore">
          {{ showMoreText }}
        </div>
      </v-col>
      <v-col :cols="inlineAttachments.length ? 3 : 4">
        <v-label v-if="realAttachments.length || emailContent.has_pending_attachments">
          Attachments:
        </v-label>

        <attachment-links-list v-if="realAttachments.length" :attachments="realAttachments" dense>
          <template v-slot:action="{ attachment }">
            <v-menu v-model="attachmentMenus[attachment.id]" :close-on-content-click="false">
              <template v-slot:activator="{ props: menu }">
                <qtm-icon-btn
                  icon="mdi-chevron-down"
                  size="small"
                  v-bind="menu"
                />
              </template>
              <v-list class="qtm-border">
                <v-list-item v-if="isUnassociated && isInbound" @click="createDraftPo(attachment)">
                  <v-list-item-title>Create a Draft PO</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="orderHasPO" @click="copyToPO(internalOrder?.pos[0], attachment)">
                  <v-list-item-title>Copy to PO</v-list-item-title>
                </v-list-item>

                <v-menu v-if="orderHasQuotes" open-on-hover :open-delay="0" location="end">
                  <template v-slot:activator="{ props: submenu }">
                    <v-list-item v-bind="submenu" @click="attachmentMenus[attachment.id] = true">
                      <v-list-item-title>
                        Copy to Quote
                        <v-icon class="ml-1" size="small" color="mid-light-grey">
                          mdi-chevron-right
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list class="qtm-border">
                    <v-list-item
                      v-for="quote in internalOrder?.quotes"
                      :key="quote.id"
                      @click="copyToQuote(quote, attachment)"
                    >
                      <v-list-item-title>{{ quote.vendor?.name ?? 'No Supplier' }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-list-item @click="copyToInvoiceInbox(attachment)">
                  <v-list-item-title>Copy to Invoice Inbox</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </attachment-links-list>

        <v-tooltip v-if="emailContent.has_pending_attachments" location="bottom" z-index="9999">
          <template v-slot:activator="{ props: activator }">
            <v-icon v-bind="activator" color="red">
              mdi-alert
            </v-icon>
            <span>Pending</span>
          </template>
          <span>This email has pending attachments.</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="inlineAttachments.length" cols="2">
        <v-btn color="black" variant="text" size="small" @click="showInlineAttachments = !showInlineAttachments">
          Inline attachments
          <v-icon class="ml-1">
            {{ showInlineAttachments ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}
          </v-icon>
        </v-btn>
        <div v-if="showInlineAttachments">
          <attachment-links-list :attachments="inlineAttachments" dense>
            <template v-slot:action="{ attachment }">
              <v-menu
                v-if="orderHasPO || orderHasQuotes"
                v-model="attachmentMenus[attachment.id]"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ props: menu }">
                  <qtm-icon-btn
                    icon="mdi-chevron-down"
                    size="small"
                    v-bind="menu"
                  />
                </template>
                <v-list class="qtm-border">
                  <v-list-item v-if="orderHasPO" @click="copyToPO(internalOrder?.pos[0], attachment)">
                    <v-list-item-title>Copy to PO</v-list-item-title>
                  </v-list-item>

                  <v-menu v-if="orderHasQuotes" open-on-hover :open-delay="0" location="end">
                    <template v-slot:activator="{ props: submenu }">
                      <v-list-item v-bind="submenu" @click="attachmentMenus[attachment.id] = true">
                        <v-list-item-title>
                          Copy to Quote
                          <v-icon class="ml-1" size="small" color="mid-light-grey">
                            mdi-chevron-right
                          </v-icon>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <v-list class="qtm-border">
                      <v-list-item
                        v-for="quote in internalOrder?.quotes"
                        :key="quote.id"
                        @click="copyToQuote(quote, attachment)"
                      >
                        <v-list-item-title>{{ quote.vendor?.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list>
              </v-menu>
            </template>
          </attachment-links-list>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="float-right text-right">
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props: activator }">
              <qtm-btn
                v-bind="activator"
                icon
                tertiary
                @click="$emit('reply', emailContent, 'single')"
              >
                <v-icon>mdi-reply</v-icon>
              </qtm-btn>
            </template>
            <span>Reply email</span>
          </v-tooltip>

          <v-menu v-model="emailMenu" content-class="qtm-border" offset-y>
            <template v-slot:activator="{ props: menu }">
              <qtm-icon-btn v-bind="menu" icon="mdi-dots-horizontal" />
            </template>
            <v-list class="text-mid-grey">
              <div v-for="item in emailMenuItems" :key="item.title">
                <v-list-item v-if="!item.submenu?.length" @click="item.onClick">
                  <template v-slot:prepend>
                    <v-icon>{{ item.icon }}</v-icon>
                  </template>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
                <v-menu v-else open-on-hover :open-delay="0" location="end">
                  <template v-slot:activator="{ props: submenu }">
                    <v-list-item v-bind="submenu" @click="emailMenu = true">
                      <template v-slot:prepend>
                        <v-icon>{{ item.icon }}</v-icon>
                      </template>
                      <v-list-item-title>
                        {{ item.title }}
                        <v-icon class="ml-1" size="small" color="mid-light-grey">
                          mdi-chevron-right
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list class="qtm-border">
                    <v-list-item
                      v-for="subitem in item.submenu"
                      :key="subitem.title"
                      @click="subitem.onClick"
                    >
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-list>
          </v-menu>
          <div>
            <email-classification :email="emailContent" />
            <email-classification-status
              v-if="emailContent?.classification_status"
              :classification-status="emailContent.classification_status"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="rows email-content">
      <v-col class="inner-email-content text-black" cols="10" offset="1">
        <safe-html-renderer :html="emailContent.body" />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import type { Attachment, Order, PurchaseOrder, Quote } from '@quotetome/materials-api'
import { useRouter } from 'vue-router'
import AttachmentLinksList from '@/components/attachment-links-list.vue'
import EmailClassification from '@/components/emails/email-classification.vue'
import EmailClassificationStatus from '@/components/emails/email-classification-status.vue'
import MoveEmail from '@/components/admin/emails/move-email.vue'
import SafeHtmlRenderer from '@/components/admin/emails/safe-html-renderer.vue'
import { INLINE_IMAGE_CATEGORY } from '@/constants'

interface Props {
  emailContent: any,
  canCreateInvoice?: boolean,
  order?: Order | null,

}
const props = withDefaults(defineProps<Props>(), {
  canCreateInvoice: false,
  order: null
})

const emit = defineEmits(['create-invoice', 'refresh', 'reply', 'update:email-content'])

const emailOrder = ref<Order | null>(null)
const showInlineAttachments = ref(false)
const showMoveModal = ref(false)
const showMoreActive = ref(false)
const attachmentMenus = ref<any>({})
const emailMenu = ref(false)

const inlineAttachments = computed(() => props.emailContent.attachments?.filter(
  (attachment: Attachment) => attachment.category === INLINE_IMAGE_CATEGORY
) ?? [])
const internalOrder = computed(() => emailOrder.value ?? props.order)
const isInbound = computed(() => props.emailContent.email_type === 'inbound')
const isUnassociated = computed(() => !props.emailContent.rfq)
const isRead = computed(() => props.emailContent.tracking_status === 'opened')
const isBounced = computed(() => ['bounced', 'rejected'].includes(props.emailContent.tracking_status))
const realAttachments = computed(() => props.emailContent.attachments?.filter(
  (attachment: Attachment) => attachment.category !== INLINE_IMAGE_CATEGORY
) ?? [])
const orderHasPO = computed(() => internalOrder.value?.pos?.length)
const orderHasQuotes = computed(() => internalOrder.value?.quotes?.length)
const showMoreText = computed(() => (showMoreActive.value ? 'show less' : 'show more'))

const { $api, $error, $toast } = useNuxtApp()

const fetchOrder = async (orderId: number) => {
  if (!orderId) {
    return
  }

  try {
    emailOrder.value = await $api.v1.rfqs.get(orderId)
  }
  catch (error) {
    $error.report(error)
  }
}

const adminStore = useAdminStore()

const markAsRead = async () => {
  try {
    await $api.v1.notifications.email.patch(props.emailContent.id, { tracking_status: 'opened' })
    await $api.v1.notifications.email.markAsRead(props.emailContent.id)
    props.emailContent.tracking_status = 'opened'
    adminStore.removeUnreadEmail(props.emailContent)
    emit('refresh')
  }
  catch (error) {
    $error.report(error)
  }
}

const markAsUnbounced = () => {
  markAsRead()
  adminStore.removeBouncedEmail(props.emailContent)
}

const onEmailUpdate = (email) => {
  if (email.rfq) {
    fetchOrder(email.rfq)
  }

  emit('update:email-content', email)
}

const close = () => {
  showMoveModal.value = false
  emit('refresh')
}

const copyEmailPdfToPo = async () => {
  const copy = await copyEmailPdfToModel(internalOrder.value?.pos[0].id, 'purchaseorder')

  if (copy) {
    internalOrder.value?.pos[0].attachments.po_attachments.push(copy)
  }
}

const copyEmailPdfToQuote = async (quote: Quote) => {
  const copy = await copyEmailPdfToModel(quote.id, 'quote')

  if (copy) {
    quote.attachments.push(copy)
  }
}

const copyEmailPdfToModel = async (id: number, model: string) => {
  try {
    const data = { content_type: model, object_id: id }
    const copy = await $api.v1.notifications.email.saveAsPdf(props.emailContent.id, data)
    emailMenu.value = false
    $toast.success(`Email saved as PDF and copied to ${model}`)
    return copy
  }
  catch (error) {
    $toast.error('Email copy failed')
    $error.report(error)
  }
  return null
}

const copyToModel = async (id: number, model: string, attachment: Attachment) => {
  try {
    const copy = await $api.v1.attachments.copy(attachment.id, { content_type: model, object_id: id })
    attachmentMenus.value[attachment.id] = false
    $toast.success('Attachment copied')
    return copy
  }
  catch (error) {
    $toast.error('Attachment copy failed')
    $error.report(error)
  }
  return null
}

const copyToPO = async (po: PurchaseOrder, attachment: Attachment) => {
  const copy = await copyToModel(po.id, 'purchaseorder', attachment)

  if (copy) {
    po.attachments.po_attachments.push(copy)
  }
}

const copyToQuote = async (quote: Quote, attachment: Attachment) => {
  const copy = await copyToModel(quote.id, 'quote', attachment)

  if (copy) {
    quote.attachments.push(copy)
  }
}

const copyToInvoiceInbox = async (attachment: Attachment) => {
  attachmentMenus.value[attachment.id] = false

  try {
    await $api.v1.attachments.invoice(attachment.id)
    $toast.success('Attachment copied to invoice inbox')
  }
  catch (error) {
    $error.report(error)
  }
}

const markAsUnread = async () => {
  try {
    await $api.v1.notifications.email.patch(props.emailContent.id, { tracking_status: 'delivered' })
    props.emailContent.tracking_status = 'delivered'
    emit('refresh')
  }
  catch (error) {
    $error.report(error)
  }
}

const toggleShowMore = () => (showMoreActive.value = !showMoreActive.value)

const formatEmailList = (emailList) => {
  if (emailList) {
    if (emailList.includes(',')) {
      return emailList.replace(/,/g, ';\n')
    }
  }

  return emailList
}

const ordersStore = useOrdersStore()
const router = useRouter()

const createDraftPo = async (quoteAttachment: Attachment) => {
  try {
    const [user] = await $api.v1.users.list({ email: props.emailContent.from_address })

    ordersStore.setOrderFromEmail({
      unassociated_email: props.emailContent.id,
      owner: user,
      quote_attachment: quoteAttachment
    })
    router.push({ name: 'purchase-orders-new' })
  }
  catch (error) {
    $error.report(error)
  }
}

const emailMenuItems = computed(() => {
  const quotesSubMenu = [] as any

  internalOrder.value?.quotes?.forEach(quote => {
    quotesSubMenu.push({
      title: quote.vendor?.name,
      onClick: () => copyEmailPdfToQuote(quote),
    })
  })
  const items = [
    {
      title: 'Reply all',
      icon: 'mdi-reply-all',
      onClick: () => emit('reply', props.emailContent, 'all'),
    },
    {
      title: 'Forward',
      icon: 'mdi-forward',
      onClick: () => emit('reply', props.emailContent, 'forward'),
    },
    {
      title: 'Remove BOUNCED notification',
      icon: 'mdi-alert',
      onClick: markAsUnbounced,
      exclude: !isBounced.value,
    },
    {
      title: 'Move to another order',
      icon: 'mdi-folder-move',
      onClick: () => (showMoveModal.value = true)
    },
    {
      title: 'Mark as unread',
      icon: 'mdi-email',
      onClick: markAsUnread,
      exclude: !(isInbound.value && isRead.value),
    },
    {
      title: 'Mark as read',
      icon: 'mdi-email-open',
      onClick: markAsRead,
      exclude: !(isInbound.value && !isRead.value),
    },
    {
      title: 'Pin email to screen',
      icon: 'mdi-pin',
      onClick: () => adminStore.showGlobalEmailViewer(props.emailContent),
    },
    {
      title: 'Create invoice',
      icon: 'mdi-receipt-text-plus',
      onClick: () => emit('create-invoice', props.emailContent),
      exclude: !props.canCreateInvoice || !isInbound.value,
    },
    {
      title: 'Copy email PDF to PO',
      icon: 'mdi-clipboard-plus',
      onClick: copyEmailPdfToPo,
      exclude: !orderHasPO.value,
    },
    {
      title: 'Copy email PDF to Quote',
      icon: 'mdi-clipboard-plus',
      exclude: !orderHasQuotes.value,
      submenu: quotesSubMenu
    },
  ]

  return items.filter(item => !item.exclude)
})

onMounted(() => {
  if (!props.order?.id && realAttachments.value.length) {
    fetchOrder(props.emailContent.rfq)
  }
})
</script>

<style scoped>
  .email-data {
    color: rgb(var(--v-theme-mid-light-grey)) !important;
    padding: 0px 10px 10px 10px;
    font-size: 1rem;
  }
  .email-data label {
    color: rgb(var(--v-theme-mid-dark-grey)) !important;
    font-size: 1rem;
    padding-right: 0.4rem;
  }
  .email-content {
    padding: 10px 0;
    min-height: 300px;
  }
  .theme--dark .inner-email-content {
    background-color: white;
    border-bottom: 3px solid black;
    border-right: 3px solid black;
    color: #333;
    padding: 20px;
  }
  .flexible {
    display: flex;
  }
  .truncated {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
  }
  .show-more {
    font-size: 1rem;
    color: rgb(var(--v-theme-interactive));
  }
  .show-more:hover {
    cursor: pointer;
  }
</style>
