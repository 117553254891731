<template>
  <iframe
    ref="htmlContainer"
    class="html-container"
    sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
  />
</template>

<script setup lang="ts">
export interface Props {
  html?: string
}

const props = withDefaults(defineProps<Props>(), {
  html: ''
})

const htmlContainer = ref<HTMLIFrameElement | undefined>(undefined)
const refreshScrollHeight = () => {
  const iframe = htmlContainer.value

  if (iframe) {
    iframe.style.height = `${10 + (iframe.contentWindow?.document.body.scrollHeight || 0)}px`
  }
}
const loadHTML = (html: string) => {
  const iframe = htmlContainer.value
  if (!iframe) {
    return
  }

  const iframedoc = iframe.contentDocument || iframe.contentWindow?.document

  if (!iframedoc) {
    return
  }
  // Fix for Firefox https://bugzilla.mozilla.org/show_bug.cgi?id=695868
  iframedoc.open()
  iframedoc.close()
  iframedoc.body.innerHTML = `${html} <base target="_blank" />`
  refreshScrollHeight()
  setTimeout(refreshScrollHeight, 1000)
}

watch(() => props.html, () => loadHTML(props.html))

onMounted(async () => {
  await nextTick()
  loadHTML(props.html)
})
</script>

<style scoped>
  iframe.html-container {
    border: 0;
    height: 100%;
    min-height: 380px;
    overflow-y: hidden;
    width: 100%;
  }
</style>
