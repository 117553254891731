<template>
  <v-chip
    v-if="classificationStatus"
    class="text-uppercase"
    :color="color"
    density="comfortable"
    label
    title="Email classification status"
  >
    <v-icon icon="mdi-information-outline" start />
    {{ classificationStatus.replaceAll('_', ' ') }}
  </v-chip>
</template>

<script setup lang="ts">
export interface Props {
  classificationStatus: string | null
}
const props = defineProps<Props>()

const color = computed(() => {
  switch (props.classificationStatus) {
    case 'action_in_progress':
      return 'warning'
    case 'analysing_document':
      return 'warning'
    default:
      return ''
  }
})
</script>
<style>
.report-overlay div{
  opacity: 1 !important;
}
</style>
